import React from 'react';
import Layout3 from '../components/layout4';


const HomePage = (props: any) => {

  return (
    <Layout3>
        <div style={{margin: "30px"}}>

          <h1>最新情報</h1>

<hr/>
          <h2>Ver1.0  - 2021/ 5/21</h2><br/>

          <h3>
            「ジオグラフとは」ページを修正しました。
          </h3>
          <p>
            <li>使い方を解説する動画を追加し、デザインを修正しました。
            </li>
          </p>

<hr/>
          <h2>Ver0.9  - 2021/ 4/19</h2><br/>

          <h3>
            KMLダウンロードボタンの機能を強化しました。
          </h3>
          <p>
            <li>各年のデータがスムーズに切り替わるようになりました。
            </li>
          </p>

          {/* <h3>
            そのほか、微修正を行なっております。
          </h3>
          <p>
            <li>FAQページの画像を差し替えました。
            </li>
            <li>表示の最適化を行いました。
            </li>
          </p> */}

<hr/>
          <h2>Ver0.8  - 2021/ 4/ 9</h2><br/>

          <h3>
            ジオグラフを試験公開しました。
          </h3>
          {/* <p>
            <li>本サイトの概要については、<a href="/saas"><b>ジオグラフの使い方</b></a>からご確認ください。
            </li>
          </p> */}




      </div>
    </Layout3>
  );
};

export default HomePage;

